export const primaryFontFamily = `
    font-family: "Quicksand", Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 400;
`
const primaryFont500 = `
    font-weight: 500;
    `
const primaryFont700 = `
    font-weight: 700;
    `
export const primaryFontItalic = `
    font-weight: 400;
    font-style: italic;
    `

export const secondaryFontFamily = `
    font-family: "Quicksand", Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 400;
`

const secondaryFont500 = `
    font-weight: 500;
    `

//////////////////////////
// Regular //////////////
//////////////////////////
export const regular11 = `
    font-size: 1.1rem;
    line-height: 1.9rem;
    ${primaryFontFamily};
`
export const regular12 = `
    font-size: 1.2rem;
    line-height: 2rem;
    ${primaryFontFamily};
`
export const regular14 = `
    font-size: 1.4rem;
    line-height: 2rem;
    ${primaryFontFamily};
`
export const regular16 = `
    font-size: 1.6rem;
    line-height: 2.4rem;
    ${primaryFontFamily};
`
export const regular18 = `
    font-size: 1.8rem;
    line-height: 2.6rem;
    ${primaryFontFamily};
`

export const regular24 = `
    font-size: 2.4rem;
    line-height: 3.2rem;
    ${primaryFontFamily};
`
//////////////////////////
// SemiBold //////////////
//////////////////////////
export const semibold12 = `
    font-size: 1.2rem;
    line-height: 2rem;  
    ${primaryFont500};
`
export const semibold14 = `
    font-size: 1.4rem;
    line-height: 2rem;    
    ${primaryFont500};
`
export const semibold16 = `
    font-size: 1.6rem;
    line-height: 2.4rem;
    ${primaryFont500};
`
export const semibold18 = `
    font-size: 1.8rem;
    line-height: 2.6rem;    
    ${primaryFont500};
`

//////////////////////////
// Bold /////////////////
//////////////////////////
export const bold11 = `
    font-size: 1.1rem;
    line-height: 1.2rem;    
    ${primaryFont700};
`
export const bold14 = `
    font-size: 1.4rem;
    line-height: 2rem;    
    ${primaryFont700};
`
export const bold16 = `
    font-size: 1.6rem;
    line-height: 2.4rem;
    ${primaryFont700};
`
export const bold18 = `
    font-size: 1.8rem;
    line-height: 2.8rem;
    ${primaryFont700};
`
export const bold20 = `
    font-size: 2rem;
    line-height: 2.8rem;  
    ${primaryFont700};
`
export const bold24 = `
    font-size: 2.4rem;
    line-height: 3.2rem;    
    ${primaryFont700};
`
export const bold28 = `
    font-size: 2.8rem;
    line-height: 4rem;    
    ${primaryFont700};
`
export const bold32 = `
    font-size: 3.2rem;
    line-height: 4rem;    
    ${primaryFont700};
`
export const bold36 = `
    font-size: 3.6rem;
    line-height: 4.4rem;    
    ${primaryFont700};
`
export const bold40 = `
    font-size: 4rem;
    line-height: 4.4rem;    
    ${primaryFont700};
`
export const bold48 = `
    font-size: 4.8rem;
    line-height: 5.6rem;    
    ${primaryFont700};
`

export const bold52 = `
    font-size: 5.2rem;
    line-height: 6rem;    
    ${primaryFont700};
`
export const bold64 = `
    font-size: 6.4rem;
    line-height: 7.2rem;    
    ${primaryFont700};
`
export const bold72 = `
    font-size: 7.2rem; 
    line-height: 8rem;    
    ${primaryFont700};
`


export const regularSecondary11 = `
    font-size: 1.1rem;
    line-height: 1.9rem;
    ${secondaryFontFamily};
`
export const regularSecondary12 = `
    font-size: 1.2rem;
    line-height: 2rem;
    ${secondaryFontFamily};
`
export const regularSecondary14 = `
    font-size: 1.4rem;
    line-height: 2rem;
    ${secondaryFontFamily};
`
export const regularSecondary16 = `
    font-size: 1.6rem;
    line-height: 2.4rem;
    ${secondaryFontFamily};
`
export const regularSecondary18 = `
    font-size: 1.8rem;
    line-height: 2.6rem;
    ${secondaryFontFamily};
`

//////////////////////////
// SemiBold Secondary //////////////
//////////////////////////
export const semiboldSecondary12 = `
    font-size: 1.2rem;
    line-height: 2rem;  
    ${secondaryFont500};
`
export const semibold1Secondary4 = `
    font-size: 1.4rem;
    line-height: 2rem;    
    ${secondaryFont500};
`
export const semiboldSecondary16 = `
    font-size: 1.6rem;
    line-height: 2.4rem;
    ${secondaryFont500};
`
export const semiboldSecondary18 = `
    font-size: 1.8rem;
    line-height: 2.6rem;    
    ${secondaryFont500};
`