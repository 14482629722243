import styled from "styled-components"
import breakpoints from "./breakpoints"
import { baseGridStyles } from "./base"
import { secondaryFontFamily, bold14, bold16, regular14, regular18, regularSecondary12, regularSecondary14, semiboldSecondary14, regularSecondary16, semiboldSecondary16 } from "./typography"

const listItem = styled.li`
  display: block;

  &:nth-child(9) {
    background-color: var(--light-blue-darkest);

    @media screen and (min-width: ${breakpoints.sm}px) {
      background-color: transparent;
    }
    a {
      text-align: center;
      color: var(--white);

      @media screen and (min-width: ${breakpoints.sm}px) {
        color: ${props => props.hasHero ? 'var(--white)' : 'var(--light-blue-darkest)'};
      }

      &:focus,
      &:visited,
      &:hover {
        color: var(--white);
        @media screen and (min-width: ${breakpoints.sm}px) {
          color: ${props => props.hasHero ? '#ffffff8c' : 'var(--light-blue-darkest)'};
        }
      }
      &:visited{
        @media screen and (min-width: ${breakpoints.sm}px) {
          color: ${props => props.hasHero ? 'var(--white)' : 'var(--light-blue-darkest)'};
        }
      }
    }
  }
  a {
    text-decoration: none;
    display: block;
    width: 100%;
    padding: 1.6rem;
    cursor: pointer;
    outline: none;
    white-space: nowrap;
    border-bottom: 0;
    color: var(--light-blue-darkest);
    @media screen and (min-width: ${breakpoints.sm}px) {
      color: ${props => props.hasHero ? 'var(--white)' : 'var(--light-blue-darkest)'};
    }

    &:focus,
    &:visited,
    &:hover {
      
      color: var(--light-blue-darkest);
      @media screen and (min-width: ${breakpoints.sm}px) {
        border-bottom: 0;
        color: ${props => props.hasHero ? 'var(--white)' : 'var(--light-blue-darkest)'};
      }
      /* background: var(--white); */
    }
  }
`

const StyledHeader = styled.header`
  position: relative;
  z-index: 3;
  padding: 0;
  border-bottom: ${props => props.displayNavBorder ? "2px solid var(--light-blue-darkest)" : "0"};

  @media screen and (min-width: ${breakpoints.sm}px) {
    padding: 1.6rem calc((24 / 720) * 100vw) 1.6rem;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    padding: 0.8rem calc((24 / 1024) * 100vw) 0.6rem;
  }
  @media screen and (min-width: ${breakpoints.l}px) {
    padding: 2.3rem calc((24 / 1024) * 100%) 2.3rem;
  }
`

const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 130.4rem;
  justify-content: space-between;
  /* align-items: baseline; */
  margin: 0 auto;
  @media screen and (min-width: ${breakpoints.sm}px) {
    display: grid;
    align-items: center;
  }

  @media screen and (min-width: 1304px) {
    padding: 0;
  }
`

const LogoButtonContainer = styled.div`
  ${baseGridStyles}
  grid-row-gap: 0;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  width: 100%;
  @media screen and (min-width: ${breakpoints.sm}px) {
    display: inline;
    grid-column: 1 / 3;
    padding: 0;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    grid-column: 1 / 4;
  }
`

const Logo = styled.h1`
  margin: 0;
  grid-column: 1 / 4;
  order: 1;
  justify-content: flex-start;
  display: flex;
  a,
  a:hover,
  a:focus {
    display: block;
    border-bottom: 0;
  }

  @media screen and (min-width: ${breakpoints.sm}px) {
    padding-left: 0;
  }
`

const Tagline = styled.p`
  grid-column: 1 / 4;
  order: 3;
  margin-top: -1rem;
  color: #009eff;
  ${secondaryFontFamily}
  ${regularSecondary12}
  letter-spacing: -0.06rem;
  position: absolute !important;
  height: 1px; width: 1px; 
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  @media screen and (min-width: ${breakpoints.md}px) {
    ${regularSecondary14}
    margin-top:-1.7rem
  }
`



const NavButton = styled.button`
  grid-column: 5;
  grid-row: 1 / 3;
  order: 2;
  display: block;
  border: none;
  cursor: pointer;
  outline: none;
  background: ${props => props.hasHero ? 'transparent' : 'var(--white)'};
  transition: background-color 0.35s ease-in-out;
  padding: 0;
  align-items: center;
  justify-content: flex-end;
  display: flex;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  svg {
    position: relative;
    top: 0.2rem;
    width: 2.4rem;
    height: 2.4rem;

    path {
      fill: ${props => !props.hasHero ? 'var(--light-blue-darkest)' : 'var(--white)'};
    } 
  }
  &:focus,
  &:hover {
    /* background: var(--grey-lighter); */
    color: var(--grey-darker);
  }
  
  .topBun {
    transform: ${props => (props.isParentOpen ? "rotate(45deg)" : "0")};
    transition: all 0.35s ease-in-out;
    transform-origin: 0.1rem 0.4rem;
  }

  .filling {
    opacity: ${props => (props.isParentOpen ? "0" : "1")};
    transition: opacity 0.15s ease-in-out;
  }

  .bottomBun {
    transform: ${props => (props.isParentOpen ? "rotate(-45deg)" : "0")};
    transition: all 0.35s ease-in-out;
    transform-origin: 0 1rem;
  }

  @media screen and (min-width: ${breakpoints.sm}px) {
    display: none;
  }
`

const NavMenu = styled.nav`
  max-height: ${props => (props.isParentOpen ? "57.6rem" : "0")};
  overflow: hidden;
  transition: max-height 0.35s;
  background: ${props => (props.isParentOpen ? "var(--white)" : "transparent")};
  width: 100%;
  

  @media screen and (min-width: ${breakpoints.sm}px) {
    max-height: ${props => (props.isParentOpen ? "100%" : "100%")};
    margin-bottom: 0;
    background: transparent;
    width: auto;
    overflow: visible;
    grid-column: 3 / 8;
    justify-content: flex-end;
    display: flex;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    background: ${props => (props.hasHero ? "var(--white)" : "transparent")};
    grid-column: 4 / 13;
  }
`

const ParentNavList = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding-left: 0;
  border-top: ${props => (props.isParentOpen ? "1px solid var(--light-blue-darkest)" : "0px solid var(--white)")};
  transition: border-top 0.2s ease-in-out;
  @media screen and (min-width: ${breakpoints.sm}px) {
    border-top: 0px solid var(--light-blue-darkest);
    flex-direction: row;
    align-items: center;
    margin-bottom: 0;
    width: auto;
    li:nth-child(2),
    li:nth-child(3),
    li:nth-child(4),
    li:nth-child(5) {
      display: none;
    }
  }

  @media screen and (min-width: ${breakpoints.md}px) {
    margin-top: 0;
    
    
  }
  @media screen and (min-width: ${breakpoints.l}px) {
    margin-top: 0;
  }
`

const ParentNavListItem = styled(listItem)`
  a {
    border-bottom: 1px solid var(--light-blue-darkest);
    ${regular18};
    font-weight:500;
    padding: 1.6rem;
    text-align: left;
    @media screen and (min-width: ${breakpoints.sm}px) {
      border-bottom: 0px solid var(--white);
    }
    &:focus,
    &:hover {
      /* background: var(--white); */
      /* border-bottom: 0; */
    }
    &.active,
    &.active:hover {
      ${bold16};
    }
  }
  @media screen and (min-width: ${breakpoints.sm}px) {
    position: ${props => (props.hasChildren ? "relative" : "static")};
    a {
      padding: 1.1rem 0.6rem;
      ${regular14};
      font-weight:700;
      &:focus,
      &:hover,
      &.active:hover,
      &.active:focus {
        /* background: var(--grey-lighter); */
        /* color: var(--light-blue-dark); */
      }
      &.active,
      &.active:hover,
      &.active:focus {
        ${semiboldSecondary14};
        background: transparent;
        cursor: default;
      }
    }
  }

  @media screen and (min-width: ${breakpoints.md}px) {
    a {
      ${regularSecondary16};
      padding: 1.2rem 1.6rem;
      font-weight: ${props => (props.hasHero ? "700" : "500")};
      &:focus,
      &:hover,
      &.active:hover,
      &.active:focus {
        ${regularSecondary16};
        color: ${props => (props.hasHero ? "#ffffff8c" : "var(--light-blue-dark)")};
        font-weight: ${props => (props.hasHero ? "700" : "500")};
      }

      &.active,
      &.active:hover,
      &.active:focus {
        ${semiboldSecondary16};
        background: transparent;
        font-weight: ${props => (props.hasHero ? "700" : "500")};
      }
    }
  }
  @keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(228, 109, 45, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(228, 109, 45, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(228, 109, 45, 0);
	}
}

&.free-trial {
    position: absolute;
    top: 0.8rem;
    right: 5.7rem;

    @media screen and (min-width: ${breakpoints.sm}px) {
      position: relative;
      right: 0;
      top: 0;
      margin-left:1.2rem;
    }
    @media screen and (min-width: ${breakpoints.l}px) {
      top: 0.2rem;
    }
    a {
      ${bold14}
      border-radius: 100px;
      background: ${props => props.hasHero ? 'transparent' : 'var(--white)'};
      color: ${props => props.hasHero ? 'var(--white)' : 'var(--light-blue-darkest)'};
      border: 2px solid ${props => props.hasHero ? 'var(--white)' : 'var(--light-blue-darkest)'};
      padding: 0.4rem 1.2rem;
      &:hover,
      &:focus {
        ${bold14}
        background: transparent;
        ${semiboldSecondary14};
        color: ${props => props.hasHero ? 'var(--white)' : 'var(--light-blue-darkest)'};
        cursor: pointer;
        /* border-bottom: 0; */
        &:after {
          animation: none;
        }
      }

      @media screen and (min-width: ${breakpoints.sm}px) {
        padding: 0.8rem ​1.6rem;
      }
    }
  }
`

const ChildNavListItemButton = styled.button`
  display: none;
  width: 100%;
  padding: 1.6rem;
  cursor: pointer;
  border: none;
  border-radius: 8px 8px 0 0;
  background: ${props => props.isChildMenuOpen ? 'var(--white)' : 'transparent'};
  /* transition-delay: background-color 0.35s ease-in-out; */
  outline: none;
  white-space: nowrap;
  &:focus,
  &:hover {
    /* background: var(--grey-lightest); */
    color: ${props => props.isChildMenuOpen ? 'var(--grey-darker)' : 'var(--light-blue-darkest)'};
  }

  &:hover {
    @media screen and (min-width: ${breakpoints.sm}px) {
      .button-text {
      color: ${props => props.hasHero && !props.isChildMenuOpen ? '#ffffff8c' : 'var(--light-blue-darkest)'};
      } 
      svg path {
      fill: ${props => props.hasHero && !props.isChildMenuOpen ? '#ffffff8c' : 'var(--light-blue-darkest)'};
      }
    }
  }

  .button-text {
    margin-right: 1rem;
    color: var(--light-blue-darkest);


    &:hover,
    &:active,
    &:focus {
      /* color: ${props => props.isChildMenuOpen ? 'var(--light-blue-darkest)' : 'var(--white)'}; */
    }

    @media screen and (min-width: ${breakpoints.sm}px) {
      font-weight: ${props => (props.hasHero ? "700" : "500")};
      color: ${props => props.hasHero && !props.isChildMenuOpen ? 'var(--white)' : 'var(--light-blue-darkest)'};
      &:hover,
      &:active,
      &:focus {
        /* color: ${props => !props.isChildMenuOpen ? '#ffffff8c' : 'var(--white)'}; */
      }
    }
  }

  .chevron {
    transform: ${props =>
    props.isChildMenuOpen ? "rotate(-180deg)" : "rotate(0)"};
    transition: transform 0.35s ease-in-out;

    @media screen and (min-width: ${breakpoints.sm}px) {
      margin-bottom: 0.1rem;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
      margin-bottom: 0;
    }
    
    path {
      fill: var(--light-blue-darkest);
      fill: ${props => props.hasHero && !props.isChildMenuOpen ? 'var(--white)' : 'var(--light-blue-darkest)'};
      
      
/* #ffffff8c */
    }
    &:hover,
    &:active,
    &:focus {
      fill: var(--light-blue-darkest);
    }
    @media screen and (min-width: ${breakpoints.md}px) {
      width: 1.7rem;
      height: 0.9rem;
    }


  }

  @media screen and (min-width: ${breakpoints.sm}px) {
    display: block;
    padding: 1.1rem 0.6rem;
    /* background: ${props =>
    props.isChildMenuOpen ? "var(--grey-lighter)" : "var(--white)"}; */
    ${regular14}
    &:focus {
      /* background: var(--grey-lighter); */
    }
  }

  @media screen and (min-width: ${breakpoints.md}px) {
    padding: 1.2rem 1.6rem;
    ${regularSecondary16}
  }
`

const ChildNavList = styled.ul`
  display: none;
  padding-left: 0;

  @media screen and (min-width: ${breakpoints.sm}px) {
    display: block;
    position: absolute;
    z-index: 3;
    top: 1.6em;
    left: 0;
    flex-direction: row;
    width: auto;
    max-height: ${props => (props.isChildMenuOpen ? "20rem" : "0")};
    overflow: hidden;
    transition: height 0.35s ease-in-out;
    background: var(--white);
    border-radius: 0 8px 8px 8px;
    min-width: 16.4rem;

    li:nth-child(2),
    li:nth-child(3),
    li:nth-child(4) {
      display: block;
    }
  }

  @media screen and (min-width: ${breakpoints.md}px) {
    top: 1.95em;
    max-height: ${props => (props.isChildMenuOpen ? "20.8rem" : "0")};
  }

  @media screen and (min-width: ${breakpoints.l}px) {
    top: 1.75em
  }
`

const ChildNavListItem = styled(listItem)`
  @media screen and (min-width: ${breakpoints.sm}px) {
    
    position: ${props => (props.hasChildren ? "relative" : "static")};

    a {
      ${regularSecondary12};
      padding: 1.1rem 0.6rem;
      font-weight: ${props => (props.hasHero ? "700" : "500")};
      &:focus,
      &:hover {
        /* background: var(--white); */
      }

      &.active,
      &.active:focus,
      &.active:hover {
        /* background: var(--grey-mid); */
      }
    }
  }

  @media screen and (min-width: ${breakpoints.md}px) {
    a {
      ${regularSecondary16};
      font-weight: ${props => (props.hasHero ? "700" : "500")};
      padding: 1.2rem 1.6rem;
    }
  }
`

export {
  HeaderContent,
  StyledHeader,
  Logo,
  Tagline,
  LogoButtonContainer,
  NavButton,
  NavMenu,
  ParentNavList,
  ParentNavListItem,
  ChildNavListItemButton,
  ChildNavList,
  ChildNavListItem,
}
