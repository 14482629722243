import React from "react"
import { Link } from "gatsby"

import { StyledNotification } from "../styledComponents/notification"
const EarlyBird = ({ discountValue }) => {
    return (
        <>
            <StyledNotification>
             Early bird, get {discountValue}% off <Link to="/level-1">Level 1</Link>.
            </StyledNotification>
        </>
    )
}

export default EarlyBird
