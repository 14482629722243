import styled from "styled-components"
import { regular16} from "./typography"
const StyledNotification = styled.div`
    display: block;
    text-align: center;
    background: var(--green-strong);
    padding: 1.6rem;
    color: var(--white);
    ${regular16}
    font-weight: 700;
    a, a:link, a:visited, a:hover, a:active {
        color: var(--white);
        border-bottom: 2px dotted var(--white)
    }
`

export {
    StyledNotification
}