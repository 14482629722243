import styled from "styled-components"
import breakpoints from "./breakpoints"
import {
  regular14,
  regular16,
  semibold14,
  semibold16,
} from "./typography"
import { baseGridStyles } from "./base";

const CookieContainer = styled.div`
  margin: 0 auto;
  background: var(--green-strong);
  width: 100%;
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  z-index: 3;
  overflow: hidden;
    
  div {
    display: block;
    padding: 1.6rem;
    text-align: center;
    ${baseGridStyles}

      @media screen and (min-width: ${breakpoints.sm}px) {
        text-align: left;
        padding: 1.6rem calc((24/720)*100%);
      }

      @media screen and (min-width: ${breakpoints.md}px) {
        padding: 1.6rem calc((24/1024)*100%);
        
      }
  }
   p {
    grid-column: span 4;
    margin: 0 auto;
    ${regular14};
    color: white;
    
    @media screen and (min-width: ${breakpoints.sm}px) {
      margin: 0;
      &:last-of-type{
        margin-bottom: 0;
      }
    }
    @media screen and (min-width: ${breakpoints.md}px) {
      ${regular16};
      grid-column-start: 2;
      grid-column-end: 10;
    }

    a {
      
      color: var(--white);
      text-decoration: underline;
      ${semibold14};
      border-bottom: 0;

      &:hover,
      &:focus {
        color: var(--white);
        text-decoration: underline;
        border-bottom: 1px dotted var(--light-blue-dark);
      }

      @media screen and (min-width: ${breakpoints.md}px) {
        ${semibold16};
      }
    }
  }
`

const CookieButton = styled.button`
  grid-column: span 4;
  margin: 0 auto;
  border: 0;
  background: white;
  ${semibold14};
  line-height: 1.6rem;
  color: var(--light-blue-darkest);
  border-radius: 100px;
  cursor: pointer;
  outline: 0;
  white-space: nowrap;
  padding: 1.4rem;
  line-height: 1.4rem;

  @media screen and (min-width: ${breakpoints.sm}px) {
    grid-column: span 2;
    margin: 0 0 auto auto;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    ${semibold16};
    padding: 1.6rem;
    line-height: 1.6rem;
  }

  &:hover,
  &:focus {
    background: var(--light-blue-darkest);
    color: var(--white);
  }
`

export { CookieButton, CookieContainer }
