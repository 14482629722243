import React, { useState, useEffect, useRef } from "react"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import { useStaticQuery, graphql, Link } from "gatsby"
import {
  StyledHeader,
  HeaderContent,
  NavButton,
  LogoButtonContainer,
  Logo,
  Tagline,
  NavMenu,
  ChildNavList,
  ChildNavListItemButton,
  ChildNavListItem,
  ParentNavListItem,
  ParentNavList,
} from "../styledComponents/header"
import Icon from "../components/icon"
import KaarmiBeingLogo from "./kaarmiBeingLogo"

const Header = ({ displayNavBorder, hasHero }) => {
  const breakpoints = useBreakpoint()

  const {
    wpMenu: {
      menuItems: { nodes },
    },
  } = useStaticQuery(graphql`
    {
      wpMenu(name: { eq: "Header Menu" }) {
        name
        menuItems {
          nodes {
            url
            label
            id
            childItems {
              nodes {
                label
                url
                id
              }
            }
          }
        }
      }
    }
  `)

  const [isParentOpen, setIsParentOpen] = useState(false)
  const [isChildMenuOpen, setIsChildMenuOpen] = useState(false)

  const wrapperRef = useRef(null)
  useHandleClickOutsideElement(wrapperRef)

  function useHandleClickOutsideElement(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsChildMenuOpen(false)
        }
      }
      document.addEventListener("mousedown", handleClickOutside)
      return () => {
        document.removeEventListener("mousedown", handleClickOutside)
      }
    }, [ref])
  }

  return (
    <>
      <StyledHeader displayNavBorder={displayNavBorder}>
        <HeaderContent>
          <LogoButtonContainer>
            <Logo hasHero={hasHero}>
              <Link to="/" aria-label="KaarmiBeing">
              {
                breakpoints.xs || breakpoints.sm || breakpoints.md
                  ? isParentOpen && <KaarmiBeingLogo /> || <KaarmiBeingLogo hasHero={hasHero} />
                  : <KaarmiBeingLogo hasHero={hasHero} />
              }
                {/* {isParentOpen && <KaarmiBeingLogo /> || <KaarmiBeingLogo hasHero={hasHero} />} */}
                <Tagline>7 steps to your wellbeing</Tagline>
              </Link>
            </Logo>

            {isParentOpen && <NavButton
              tabIndex={
                breakpoints.xs || breakpoints.sm || breakpoints.md
                  ? "auto"
                  : "-1"
              }
              type="button"
              onClick={() => setIsParentOpen(!isParentOpen)}
              onBlur={() => setIsParentOpen(false)}
              isParentOpen={isParentOpen}
              aria-label="Menu"
            >
             <Icon name="nav" />
            </NavButton> || <NavButton
              hasHero={hasHero}
              tabIndex={
                breakpoints.xs || breakpoints.sm || breakpoints.md
                  ? "auto"
                  : "-1"
              }
              type="button"
              onClick={() => setIsParentOpen(!isParentOpen)}
              onBlur={() => setIsParentOpen(false)}
              isParentOpen={isParentOpen}
              aria-label="Menu"
            >
             <Icon name="nav" />
            </NavButton>}


            
          </LogoButtonContainer>
          <NavMenu isParentOpen={isParentOpen} onFocus={() => setIsParentOpen(true)} onBlur={() => setIsParentOpen(false)}>
            <ParentNavList isParentOpen={isParentOpen}>
              {nodes.map(menuItem => {
                if (menuItem.childItems.nodes.length) {
                  return (
                    <ParentNavListItem
                      key={menuItem.id}
                      hasChildren
                      ref={wrapperRef}
                    >
                      <ChildNavListItemButton
                        hasHero={hasHero}
                        tabIndex="-1"
                        isChildMenuOpen={isChildMenuOpen}
                        onClick={() => setIsChildMenuOpen(!isChildMenuOpen)}
                      >
                        <span className="button-text">{menuItem.label}</span>
                        <svg
                          width="11"
                          height="6"
                          viewBox="0 0 11 6"
                          className="chevron"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M-2.09808e-05 0.845384C-2.09808e-05 0.702218 0.0623218 0.559053 0.18701 0.444524C0.436385 0.215462 0.826033 0.215462 1.07541 0.444524L5.29921 4.32427L9.53861 0.444524C9.78796 0.215462 10.1776 0.215462 10.427 0.444524C10.6764 0.673587 10.6764 1.0315 10.427 1.26056L5.7512 5.55545C5.62651 5.67 5.47065 5.72727 5.31479 5.72727C5.14334 5.72727 4.98748 5.67 4.87838 5.55545L0.202597 1.26056C0.0623229 1.13171 -2.09808e-05 0.988549 -2.09808e-05 0.845384Z" />
                        </svg>
                      </ChildNavListItemButton>
                      <ChildNavList isChildMenuOpen={isChildMenuOpen}>
                        {menuItem.childItems.nodes.map(childItem => (
                          <ChildNavListItem key={childItem.id}>
                            <Link
                              activeClassName="active"
                              to={childItem.url}
                              tabIndex={!breakpoints.xs ? "auto" : "-1"}
                              onFocus={e => setIsChildMenuOpen(true)}
                              onBlur={e => setIsChildMenuOpen(false)}
                            >
                              {childItem.label}
                            </Link>
                          </ChildNavListItem>
                        ))}
                      </ChildNavList>
                    </ParentNavListItem>
                  )
                } else {
                  return (
                    <ParentNavListItem
                      hasHero={hasHero}
                      key={menuItem.id}
                      tabIndex={isParentOpen ? "auto" : "-1"}
                    >
                      <Link activeClassName="active" to={menuItem.url || "/"}>
                        {menuItem.label}
                      </Link>
                    </ParentNavListItem>
                  )
                }
              })}
              {isParentOpen && <ParentNavListItem className='free-trial' key='free-trial'>
                  <Link to='/free-trial'>Free trial</Link>
                </ParentNavListItem> || <ParentNavListItem hasHero={hasHero} className='free-trial' key='free-trial'>
                      <Link to='/free-trial'>Free trial</Link>
                    </ParentNavListItem>
                }
            </ParentNavList>
          </NavMenu>
        </HeaderContent>
      </StyledHeader>
    </>
  )
}

export default Header