import styled, { css } from "styled-components"
import breakpoints from "./breakpoints"
import {
  bold16,
  bold18,
  bold20,
  bold24,
  bold28,
  bold32,
  bold40,
  bold48,
  bold64,
  regular12,
  regular14,
  regular16,
  regular18,
} from "./typography"
import { createGlobalStyle } from "styled-components"
import { primaryFontFamily } from "../styledComponents/typography"

export const GlobalStyle = createGlobalStyle`

  *,
  &:after,
  &:before {
    box-sizing: border-box;
  }

  html {
    font-size: 62.5%;
  }

  html,
  body {
    ${primaryFontFamily}
    background: var(--white);
  }
  
  body{
    min-width: 32rem;
  }

  /* Link */
  a {
    color: var(--light-blue-darker);
    text-decoration: none;
    word-break: break-word;
    border-bottom: 1px dotted var(--light-blue-dark);
    &:visited {
      color: var(--light-blue-darkest);
      border-bottom: 1px dotted var(--light-blue-darker);
    }

    &:hover, 
    &:active,
    &:focus {
      color: var(--light-blue-dark);
      border-bottom: 1px dotted var(--light-blue-dark);
    }
  }

 /* colors */
  :root {
    --light-blue-light: #cdd5ff;
    --light-blue-lightest: #c4d8dc;
    --light-blue-lighter: #5DB6EC;
    --light-blue-mid: #8ED3FE;
    --light-blue-dark: #56bbf9;
    --light-blue-darker: #217295;
    --light-blue-darkest: #009eff;
    
    --grey-lightest: #f9f8f7;
    --grey-lighter: #eeece9;
    --grey-light: #ddd9d4;
    --grey-mid: #bab2a8;
    --grey-mid-dark: #67625d;
    --grey-dark: #3d3b37;
    --grey-darker: #242220;
    --grey-darkest: #131211;

    --green-mid: #30d35e;
    --green-light: #53EB7E;
    --white: #ffffff;
    --gold-mid: #F3B844;
    --gold-light: #FFCC00;
    --green-mid-dark: #85B254;
    --red-light: #EF2D2D;
    --red-mid: #EF2D2D;


    --green-strong: #0dab46;
    --green-mid: #78cbc9;
  }

  p,
  h2,
  h3,
  h4,
  h5,
  h6,
  ul,
  ol,
  blockquote,
  figure,
  figurecaption,
  pre, dl {
    margin: 0;
    @media screen and (min-width: 1304px) {
      margin: 0 auto;
    }
  }
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 1.6rem;
    ${bold16};
    color: var(--green-strong);
  }
  h1 {
    ${bold24};
    margin-bottom: 0.4rem;
    margin-top: 0;
    @media screen and (min-width: ${breakpoints.sm}px) {
      ${bold32}; 
      margin-bottom: 0.8rem;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
      ${bold48}; 
      margin-bottom: 1.6rem;
    }
    @media screen and (min-width: ${breakpoints.l}px) {
      ${bold64};
    }
  }
  h2 {
    ${bold20};
    @media screen and (min-width: ${breakpoints.sm}px) {
      ${bold28};
    }
    @media screen and (min-width: ${breakpoints.l}px) {
      ${bold40};
    }
  }
  h3 {
    ${bold18};
    @media screen and (min-width: ${breakpoints.sm}px) {
      ${bold20};
    }
    @media screen and (min-width: ${breakpoints.l}px) {
      ${bold24};
    }
  }
  h4 {
    ${bold16};
    @media screen and (min-width: ${breakpoints.sm}px) {
      ${bold18};
    }
    @media screen and (min-width: ${breakpoints.l}px) {
      ${bold20};
    }
  }
  h5 {
    ${bold16};
    font-weight: 840;
    @media screen and (min-width: ${breakpoints.sm}px) {
      font-weight: 900;
    }
    @media screen and (min-width: ${breakpoints.l}px) {
      ${bold18};
    }
  }
  h6 {
    ${bold16};
    font-weight: 760;
    @media screen and (min-width: ${breakpoints.sm}px) {
      font-weight: 840;
    }
    @media screen and (min-width: ${breakpoints.l}px) {
      font-weight: 900;
    }
  }

  p {
    color: var(--grey-darker);
    margin-top: 0;
    margin-bottom: 0;
    ${regular16};
    @media screen and (min-width: ${breakpoints.l}px) {
      ${regular18};
    }
  }
  em {
    font-style: italic;
  }
  ul,
  ol {
    ${regular16};
    padding-left: 2rem;
    margin-top: 1.6rem;
    transition: padding-left 0.25s ease-out;

    @media screen and (min-width: 912px) {
      padding-left: 0;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
      padding-left: 2rem;
    }
    @media screen and (min-width: ${breakpoints.l}px) {
      ${regular18};
      padding-left: 2rem;
    }
    @media screen and (min-width: 1404px) {
      padding-left: 0;
    }
  }
  ul ul,
  ol ol, 
  ul ol,
  ol ul {
    padding-left: 0;
    @media screen and (min-width: ${breakpoints.sm}px) {
      padding-left: 2rem;
    }
  }
  ol li::marker {
    font-size: 1.4rem;
  }
  ol ol {
    list-style-type: lower-alpha;
  }
  ol ol ol {
    list-style-type: lower-roman;
  }
  li ul,
  li ol {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
    margin-left: 0;
    margin-right: 0;
  }
  blockquote {
    margin: 1.6rem 1.6rem;
    padding: 1.6rem;
    border-left: 1px solid var(--light-blue-mid);
    @media screen and (min-width: ${breakpoints.sm}px) {
      width: calc((442 / 720) * 100vw);
      margin-left: auto;
      margin-right: auto;
      padding: 2.4rem;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
      width: calc((480 / 1024) * 100vw);
    }
    @media screen and (min-width: ${breakpoints.l}px) {
      width: calc((642 / 863) * 100%);
    }
    p {
      ${bold20};
      margin-left: 0;
      margin-top: 0;
      margin-right: 0;
      @media screen and (min-width: ${breakpoints.sm}px) {
        ${bold24};
      }
      @media screen and (min-width: ${breakpoints.md}px) {
        margin-right: 0;
      }
    }
    cite {
      display: block;
      margin-top: 0.8rem;
      ${regular14};
      font-style: normal;
      margin-left: 0;
      strong {
        font-weight: 900;
      }
    }
  }
  
  figure {
    margin-top: 1.6rem;
    &:first-child{
      margin-top: 0;
      margin-left: 0;
      margin-right: 0;
    }
    
    img {
      width: 100%;
      height: auto;
      vertical-align: top;
    }

    .gatsby-image-wrapper {
      vertical-align: top;
    }

    &.wp-block-embed.is-type-video {
      position: relative;
      padding-bottom: 56.25%;
      padding-top: 3rem;
      height: 0;
      overflow: hidden;
      margin: 0;
      width: 100%;
      grid-column: span 4;

      @media screen and (min-width: ${breakpoints.sm}px) {
        grid-column: span 6;
      }
      @media screen and (min-width: ${breakpoints.md}px) {
        grid-column: 2 / 12;
      }
      @media screen and (min-width: ${breakpoints.l}px) {
        /* padding-bottom: 45%; */
      }

      article & {
        margin-top:1.6rem;
        @media screen and (min-width: ${breakpoints.l}px) {
          padding-bottom: 56.25%;
          width: 100%;
        }
      }

      iframe,  
      object,  
      embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

  }
  figcaption {
    ${regular12};
    line-height: 1.6rem;
    color: var(--grey-mid-dark);
    text-align: center;
    margin: 0.8rem 0 0;
    @media screen and (min-width: ${breakpoints.l}px) {
      ${regular12};
    }
  }
  pre {
    ${regular16};
    font-family: monospace;
    overflow-x: scroll;
    @media screen and (min-width: ${breakpoints.l}px) {
      ${regular18};
    }
  }
  dl {
    ${regular16};
    @media screen and (min-width: ${breakpoints.l}px) {
      ${regular18};
    }

    dt {
      margin-top: 1.6rem;
      ${bold16};
      font-style: italic;
    }
    dd {
      margin: 0;
    }
  }
`

export const StyledMain = styled.main`
  min-height: calc(100vh - 38.1rem);
  padding: 0;
  overflow: hidden;
  margin-top: ${props => !props.hasHero ? "0"  : '-4.8rem'};

  @media screen and (min-width: ${breakpoints.sm}px) {
    margin-top: ${props => !props.hasHero ? "0"  : '-7.4rem'};
  }
  @media screen and (min-width: ${breakpoints.l}px) {
    margin-top: ${props => !props.hasHero ? "0"  : '-12rem'};
  }
`

export const baseGridStyles = css`
  display: grid;
  grid-column: span 4;
  grid-gap: 1.6rem;
  grid-template-columns: repeat(4,1fr);
  grid-row-gap: 1.6rem;
  padding: ${props => (props.noPadding ? "0" : "1.6rem 1.6rem")};

  @media screen and (min-width: ${breakpoints.sm}px) {
    grid-column: span 6;
    grid-template-columns: repeat(6,1fr);
    column-gap: calc((16 / 672) * 100vw);
    grid-row-gap: 1.6rem;
    padding: ${props => (props.noPadding ? "0" : "2.4rem calc((24 / 720) * 100vw)")};
  }

  @media screen and (min-width: ${breakpoints.md}px) {
    margin: 0;
    grid-column: span 12;
    grid-template-columns: repeat(12, 1fr);
    column-gap: calc((16 / 1024) * 100vw);
    padding: ${props => (props.noPadding ? "0" : "2.4rem calc((24 / 1024) * 100vw)")};
  }

  @media screen and (min-width: ${breakpoints.l}px) {
    grid-row-gap: 1.6rem;
    padding: 3.2rem;
    max-width: 136.8rem;
    margin: 0 auto;
  }
`;


export const sectionHeaderFontIncrease = css`
      ${bold24};
      margin-bottom: 0;
      margin-top: 0;
      @media screen and (min-width: ${breakpoints.sm}px) {
        ${bold32};         
      }
      @media screen and (min-width: ${breakpoints.md}px) {
        ${bold48}; 
      }
      @media screen and (min-width: ${breakpoints.l}px) {
        ${bold64};
      }
`;

export const StyledBlockQuote = styled.blockquote`
  border-left: 0;
  padding: 0;
  margin: 0;
  margin-bottom: 3.2rem;
  padding-bottom: 3.2rem;
  border-bottom: 1px solid var(--grey-mid);
  width: 100%;

  &:last-of-type{
    border-bottom: 0;
  }
  section & {
    p {
      font-style: normal;
      font-weight: 500;
      margin-top: 1.6rem;
    }
  }
  cite {
    /* font-weight: 300; */
  }
  .single-quote {
    width: 4rem;
    height: 4rem;
    fill: var(--green-mid-dark);
  }
`