import styled from "styled-components"
import breakpoints from "./breakpoints"
import { baseGridStyles } from "./base"
import { regular11, regular12 } from "./typography"

const Footer = styled.footer`
  margin-bottom: 0;
  background: var(--light-blue-darkest);
  width: 100%;

  @media screen and (min-width: ${breakpoints.sm}px) {
    margin-bottom: 0;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    margin-bottom: 0;
  }
`

const NavMenu = styled.nav`
  ${baseGridStyles}
`

const NavList = styled.ul`
  grid-column: span 4;
  list-style-type: none;
  padding: 0;
  margin: 0 ;

  
  @media screen and (min-width: ${breakpoints.sm}px) {
    grid-template-columns: repeat(6,1fr);
    column-gap: calc((8 / 672) * 100%);
    margin: 0;
    &:nth-of-type(1) {
      grid-column-start: 1;
      grid-column-end: 3;
    }
    &:nth-of-type(2) {
      grid-column-start: 3;
      grid-column-end: 5;
    }
    &:nth-of-type(3) {
      grid-column-start: 5;
      grid-column-end: 7;
    }
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    &:nth-of-type(1) {
      grid-column-start: 1;
      grid-column-end: 4;
    }
    &:nth-of-type(2) {
      grid-column-start: 4;
      grid-column-end: 7;
    }
    &:nth-of-type(3) {
      grid-column-start: 7;
      grid-column-end: 10;
    }
  }
`
const NavListItem = styled.li`
  color: var(--white);
  ${regular12};

  & + li {
    margin-top: 0.4rem;
  }

  a,
  a:visited,
  a:active {
    border-bottom: 0;
    color: var(--white);
  }
  a:hover,
  a:focus {
    color: var(--light-blue-mid);
    border-bottom: 0;
  }
`
const FooterLogoHygieneContainer = styled.div`
  ${baseGridStyles}
  
  align-items: baseline;
  /* padding-top: 1.6rem; */

  @media screen and (min-width: ${breakpoints.sm}px) {
    /* padding-top: 2.4rem; */
  }
  @media screen and (min-width: ${breakpoints.l}px) {
    /* padding-top: 3.2rem; */
  }
`
const FooterLogoCopyright = styled.div`
  color: var(--white);
  ${regular11};
  line-height: 0.8rem;
  grid-column: span 4;
  /* border-top: dotted 1px var(--light-blue-mid); */
  /* padding-top:1.6rem; */
 

  svg {
    height: 2.5rem;
    width: 15rem;
    fill: var(--white);

    .logo-text-eing,
    .logo-text-b {
      fill: var(--white);
    }

    path {
      fill: var(--white);
    }
  }
  @media screen and (min-width: ${breakpoints.sm}px) {
    grid-column: span 2;
      border-top:dotted 0px var(--light-blue-mid);
      position: relative;
      &:before {
        content: " ";
        display: block;
        width: calc((672 / 720) * 100vw); 
        height: 0.1rem;
        top: 0;
        left: 0;
        position: absolute;
      }
    }
    @media screen and (min-width: ${breakpoints.md}px) {
      grid-column: span 6;
      &:before {
        width: calc((976 / 1024) * 100vw); 
      }
    }
    @media screen and (min-width: ${breakpoints.l}px) {
      &:before {
        width: 130.4rem;
      }
    }
`
const FooterCopyright = styled.span`
  display: inline-block;
  margin-left: 1.6rem;
`
const FooterHygieneList = styled.ul`
  grid-column: span 4;
  color: var(--white);
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: inline;
  @media screen and (min-width: ${breakpoints.sm}px) {
    margin: 0;
    /* padding-top:2.3rem; */
    grid-column-start: 4;
    grid-column-end: 7;
    display: inline;
    text-align: right;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    margin-top: 0;
    top: 0;
    grid-column-start: 9;
    grid-column-end: 13;
  }
`
const FooterHygieneListItem = styled.li`
  display: inline;
  margin-right: 1.6rem;
  &:last-child {
    margin-right: 0;
  }
  a,
  a:visited,
  a:active {
    ${regular11};
    color: var(--white);
    border-bottom: 0;
  }
  a:hover,
  a:focus {
    color: var(--light-blue-mid);
    border-bottom: 0;
  }

  @media screen and (min-width: ${breakpoints.sm}px) {
    margin-left: 1rem;
    display: inline;
    text-align: right;
    &:nth-child(1){
      grid-column-start: 3;
      grid-column-end: 4;
    }
    &:nth-child(2){
      grid-column-start: 4;
      grid-column-end: 5;
    }
    &:nth-child(3){
      grid-column-start: 5;
      grid-column-end: 6;
    }
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    margin-right: 0rem;
  }
`
export {
  Footer,
  NavMenu,
  NavList,
  NavListItem,
  FooterLogoHygieneContainer,
  FooterLogoCopyright,
  FooterCopyright,
  FooterHygieneList,
  FooterHygieneListItem,
}
