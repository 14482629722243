import styled from "styled-components"
import breakpoints from "./breakpoints"
 
const StyledKaarmiBeingLogo = styled.svg` 
    position: relative;
    top: 0.5rem; 
    transition: fill .4s ease;
    fill: ${props => props.hasHero ? "var(--white)"  : "var(--light-blue-darkest)"};

    @media screen and (min-width: ${breakpoints.sm}px) {
      height: auto;
      width: auto;
      top: 0;
    }

    @media screen and (min-width: ${breakpoints.md}px) {
      height: 3.5rem;
      width: 22rem;
      top: 0rem;
    }
    
    .logo-text-eing,
    .logo-text-b {
      transition: fill .4s ease;
      fill: ${props => props.hasHero ? 'var(--white)' : 'var(--light-blue-lighter)'};
    }
    path{
        fill: ${props => props.hasHero ? "var(--white)"  : "var(--light-blue-darkest)"};
        transition: fill .4s ease;
    }
`

export {
    StyledKaarmiBeingLogo
}