import React from "react"

const Icon = props => {
  const { name, width, height, shareTitle } = props
  return (
    <>
      {name === "linkedin" && (
        <svg
          width={width ? `${width}` : "24px"}
          height={height ? `${height}` : "24px"}
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title id="linkedin">
            {shareTitle} on LinkedIn
          </title>
          <path d="M19 0H5a5 5 0 00-5 5v14a5 5 0 005 5h14a5 5 0 005-5V5a5 5 0 00-5-5zM8 19H5V8h3v11zM6.5 6.732c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zM20 19h-3v-5.604c0-3.368-4-3.113-4 0V19h-3V8h3v1.765c1.396-2.586 7-2.777 7 2.476V19z"/>
        </svg>
      )}
      {name === "instagram" && (
        <svg
          width={width ? `${width}` : "24px"}
          height={height ? `${height}` : "24px"}
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title id="instagram">
          {shareTitle} on Instagram
          </title>
          <path d="M15.233 5.488c-.843-.038-1.097-.046-3.233-.046s-2.389.008-3.232.046c-2.17.099-3.181 1.127-3.279 3.279-.039.844-.048 1.097-.048 3.233s.009 2.389.047 3.233c.099 2.148 1.106 3.18 3.279 3.279.843.038 1.097.047 3.233.047 2.137 0 2.39-.008 3.233-.046 2.17-.099 3.18-1.129 3.279-3.279.038-.844.046-1.097.046-3.233s-.008-2.389-.046-3.232c-.099-2.153-1.111-3.182-3.279-3.281zM12 16.108a4.108 4.108 0 110-8.215 4.108 4.108 0 010 8.215zm4.271-7.418a.96.96 0 110-1.92.96.96 0 010 1.92zM14.667 12a2.667 2.667 0 11-5.334 0 2.667 2.667 0 015.334 0zM19 0H5a5 5 0 00-5 5v14a5 5 0 005 5h14a5 5 0 005-5V5a5 5 0 00-5-5zm.952 15.298c-.132 2.909-1.751 4.521-4.653 4.654-.854.039-1.126.048-3.299.048s-2.444-.009-3.298-.048c-2.908-.133-4.52-1.748-4.654-4.654C4.009 14.445 4 14.173 4 12c0-2.172.009-2.445.048-3.298.134-2.908 1.748-4.521 4.654-4.653C9.556 4.009 9.827 4 12 4s2.445.009 3.299.048c2.908.133 4.523 1.751 4.653 4.653.039.854.048 1.127.048 3.299 0 2.173-.009 2.445-.048 3.298z"/>
        </svg>
      )}
      {name === "facebook" && (
        <svg
          width={width ? `${width}` : "24px"}
          height={height ? `${height}` : "24px"}
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title id="facebook">
          {shareTitle} on Facebook
          </title>
          <path d="M19 0H5a5 5 0 00-5 5v14a5 5 0 005 5h14a5 5 0 005-5V5a5 5 0 00-5-5zm-3 7h-1.924C13.461 7 13 7.252 13 7.889V9h3l-.238 3H13v8h-3v-8H8V9h2V7.077C10 5.055 11.064 4 13.461 4H16v3z"/>
        </svg>
        
      )}
      {name === "nav" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          >
          <path className="topBun" d="M0 0H16V2H0V0Z"  />
          <path className="filling" d="M0 6H16V8H0V6Z"  />
          <path
            className="bottomBun"
            d="M16 12H8H0V14H16V12Z"
            
          />
        </svg>
      )}
      {name === "cross" && (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path className="topBun" d="M0 0H16V2H0V0Z"  />
        <path
          className="bottomBun"
          d="M16 12H8H0V14H16V12Z"
          
        />
      </svg>
      )}

      {name === "singlequote" && (
        <svg xmlns="http://www.w3.org/2000/svg" className="single-quote" viewBox="0 0 191.029 191.029"><path d="M44.33 88.474v15.377h38.417v82.745H0v-82.745h.002V88.474c0-31.225 8.984-54.411 26.704-68.918C38.964 9.521 54.48 4.433 72.824 4.433v44.326c-9.958 0-28.494 0-28.494 39.715zm136.777-39.715V4.433c-18.343 0-33.859 5.088-46.117 15.123-17.72 14.507-26.705 37.694-26.705 68.918v98.122h82.744v-82.745h-38.417V88.474c.001-39.715 18.537-39.715 28.495-39.715z"/></svg>
      )}
    </>
  )
}

export default Icon
