import React from "react"
import { Link } from "gatsby"
import {
  CookieContainer,
  CookieButton,
} from "../styledComponents/cookieNotice"

const CookieNotice = ({ isCookieSet, createCookie }) => {
  return (
    <>
      <CookieContainer isCookieSet={isCookieSet}>
        <div>
          <p>
            This website stores data such as cookies to enable necessary site
            functionality, including analytics. You can view details in our <Link to="/cookie-policy">cookie policy</Link>.
            </p>
          
          <CookieButton onClick={() => createCookie()}>
            I understand
          </CookieButton>
          </div>
      </CookieContainer>
    </>
  )
}

export default CookieNotice
