import React, { useState, useLayoutEffect } from "react"
import { CartProvider } from 'use-shopping-cart'
import { loadStripe } from '@stripe/stripe-js'
import { GlobalStyle, StyledMain } from "../styledComponents/base"
import CookieNotice from "../components/cookieNotice"
import EarlyBird from "../components/earlyBird"
import "../css/fonts.css"
import Header from "./header"
import FooterContent from "./footer"
import Slogan from "../components/slogan"

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY)

const Layout = ({ isHomePage, children, displayNavBorder, hasHero }) => {
  const [isCookieSet, setIsCookieSet] = useState(true)
  const [isDiscountSet, setIsDiscountCookieSet] = useState(false)
  const [discountValue, getDiscountCookieValue] = useState(false)
  
  const checkIfCookieExists = () => {
    document.cookie
      .split(";")
      .some(item => item.trim().startsWith("cookie-policy="))
      ? setIsCookieSet(true)
      : setIsCookieSet(false)
  }

  const createCookie = () => {
    document.cookie = `cookie-policy=true; path=/; host=${
      process.env.GATSBY_DOMAIN_NAME
      }; ${process.env.GATSBY_SECURE_COOKIE ? "secure;" : ""}`
    setIsCookieSet(true)
  }

  const checkDiscountCookieValue = (name) => {
    setIsDiscountCookieSet(true)
    const re = new RegExp(name + "=([^;]+)")
    const value = re.exec(document.cookie) 
    const earlyBirdDiscountName = unescape(value[1])
    let earlyBirdDiscountValue
    if(earlyBirdDiscountName === 'f3Hj2ob221') {//20% off code
      earlyBirdDiscountValue = 20
    } else if(earlyBirdDiscountName === '0Ijss23Nmx1') {// 30% off code
      earlyBirdDiscountValue = 30
    }
    getDiscountCookieValue(earlyBirdDiscountValue)
  }


  const checkIfDiscountCookieExists = () => {
    if(document.cookie.split(";").some(item => item.trim().startsWith("eb="))) { 
      checkDiscountCookieValue('eb')
    }
    else { 
      setIsDiscountCookieSet(false)
    }
  }

  const checkIfDiscountParam = () => {
    const urlParams = new URLSearchParams(window.location.search)
    const couponCode = urlParams.get('cc')
    if(couponCode){
      createDiscountCookie(couponCode)
      checkIfDiscountCookieExists('eb')
    } else {
      checkIfDiscountCookieExists('eb')
    }
  }

  const createDiscountCookie = (value) => {
    document.cookie = `eb=${value}; path=/; expires=Fri, 16 July 2021 13:14:07 GMT; host=${
      process.env.GATSBY_DOMAIN_NAME
      }; ${process.env.GATSBY_SECURE_COOKIE ? "secure;" : ""}`
      setIsDiscountCookieSet(true)
  }

  useLayoutEffect(() => {
    if (typeof window !== "undefined" || window.document) {
      checkIfCookieExists()
      // checkIfDiscountParam()
      return
    }
  }, [])

  return (
    <>
    <GlobalStyle  />
    {!isCookieSet && <CookieNotice isCookieSet={isCookieSet} createCookie={createCookie} />}
    {isDiscountSet && <EarlyBird discountValue={discountValue} /> }
    <div className="global-wrapper" data-is-root-path={isHomePage}>
        <CartProvider
        mode="client-only"
        stripe={stripePromise}
        successUrl={`https://${process.env.GATSBY_DOMAIN_NAME}/payment-success/?sessionID={CHECKOUT_SESSION_ID}`}
        cancelUrl={`https://${process.env.GATSBY_DOMAIN_NAME}/book-now/`}
        currency="GBP"
        allowedCountries={['US', 'GB', 'CA']}
        billingAddressCollection={false}
      >
          <Header displayNavBorder={displayNavBorder} hasHero={hasHero} />
          <StyledMain hasHero={hasHero}>
            {children}
          </StyledMain>
          <Slogan />
        <FooterContent isCookieSet={isCookieSet} />
        </CartProvider>
    </div>
    </>
  )
}

export default Layout
