import styled from "styled-components"
import breakpoints from "./breakpoints"


const StyledSlogan = styled.div`
    margin-bottom: 0;
    background: var(--light-blue-dark);
    width: 100%;
    text-align: center;
    color: var(--white);
    padding: 1.6rem;
    h2 {
        margin-top: 0;
        color: var(--white);
    }

    @media screen and (min-width: ${breakpoints.md}px) {
        padding: 3.2rem;
    }
    
`

export {
    StyledSlogan
}