import React from "react"
import { StyledSlogan } from "../styledComponents/slogan"

const Slogan = ( {location} ) => {
  return (
    <>
        <StyledSlogan>
            <h2>7 steps to your wellbeing</h2>
        </StyledSlogan>
    </>
  )
}
export default Slogan
